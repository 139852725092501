import { ReactElement } from "react";
import styles from "rivals/components/shared/Icons/Icons.module.scss";
import { ICON_SIZE_6, ICON_SIZE_8 } from "./constants";
import type { IconProps } from "./iconPropsTypes";

const RankUp = ({
  fill = styles.pass,
  height = ICON_SIZE_8,
  width = ICON_SIZE_6
}: IconProps): ReactElement => (
  <svg
    aria-hidden={fill === "transparent"}
    fill={fill}
    height={height}
    role="graphics-document"
    viewBox="0 0 6 8"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_5875_106244)">
      <path d="M3.70226 5.04989H0L6 0V8L3.70226 5.04989Z" fill={fill} />
    </g>
    <title>rank up</title>
  </svg>
);

export default RankUp;
