import classnames from "classnames";
import { MAX_ATHLETE_STARS } from "rivals/shared/constants";
import { setData } from "rivals/shared/utils/qa";
import styles from "./Stars.module.scss";
import type { Props, Sizes } from "./types";

const mapSizeToWrapperClassName = (size: Sizes): string => {
  switch (size) {
    case "small":
      return styles.starsWrapperSmall;
    default:
      return "";
  }
};

export const starOffMap = {
  blue: styles.starOff,
  blueOnBlack: classnames(styles.starOffBlack, styles.starStaticSize),
  yellow: styles.starOff
};

export const starOnMap = {
  blue: styles.starOnBlue,
  blueOnBlack: classnames(styles.starOnBlue),
  yellow: styles.starOnYellow
};

// TODO: https://griosf.atlassian.net/browse/RVLS-10530
// TODO: https://griosf.atlassian.net/browse/RVLS-10557
export default function Stars({
  className = "",
  dataPhilter = "",
  numStars,
  size = "default",
  startIndex = 0,
  variant = "yellow"
}: Props): React.JSX.Element {
  let starsCount = 0;

  const classNames = classnames(mapSizeToWrapperClassName(size), className);

  return (
    <div className={classNames} {...setData(dataPhilter)}>
      {Array.from({ length: MAX_ATHLETE_STARS }).map((_, i) => {
        if (i >= startIndex && starsCount <= numStars) {
          starsCount = starsCount + 1;
        }
        return (
          <div
            aria-label="star"
            className={classnames(styles.starIcon, {
              [starOnMap[variant]]: i >= startIndex && starsCount <= numStars,
              [starOffMap[variant]]: i < startIndex || starsCount > numStars
            })}
            key={i}
            role="img"
          />
        );
      })}
    </div>
  );
}
