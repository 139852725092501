/* eslint-disable no-magic-numbers */

const MAX_RGB = 255;
const RGBA_NUMBER = 4;
const RED_INDEX = 0;
const GREEN_INDEX = 1;
const BLUE_INDEX = 2;
const ALPHA_INDEX = 3;
const LIGHTNESS_INDEX = 2;
const LIGHTNESS_LEVEL = 60;

const RGBToHSL = (
  red: number,
  green: number,
  blue: number
): [number, number, number] => {
  red /= MAX_RGB;
  green /= MAX_RGB;
  blue /= MAX_RGB;
  const lightness = Math.max(red, green, blue);
  const saturation = lightness - Math.min(red, green, blue);
  const hue = saturation
    ? lightness === red
      ? (green - blue) / saturation
      : lightness === green
      ? 2 + (blue - red) / saturation
      : 4 + (red - green) / saturation
    : 0;

  return [
    60 * hue < 0 ? 60 * hue + 360 : 60 * hue,
    100 *
      (saturation
        ? lightness <= 0.5
          ? saturation / (2 * lightness - saturation)
          : saturation / (2 - (2 * lightness - saturation))
        : 0),
    (100 * (2 * lightness - saturation)) / 2
  ];
};

/* eslint-enable no-magic-numbers */

const createImage = (src: string): HTMLImageElement => {
  const newImage = new Image();
  newImage.crossOrigin = "anonymous";
  newImage.src = src;
  newImage.style.display = "none";
  return newImage;
};

const createCanvas = (
  newImageHeight: number,
  newImageWidth: number
): HTMLCanvasElement => {
  const canvas = document.createElement("canvas");
  canvas.height = newImageHeight;
  canvas.width = newImageWidth;
  return canvas;
};

const getImagePixelsFromCanvas = (
  canvas: HTMLCanvasElement,
  image: HTMLImageElement
): Uint8ClampedArray => {
  const canvasContext = canvas.getContext("2d");
  if (canvasContext) {
    canvasContext.drawImage(image, 0, 0);
    const imageData = canvasContext.getImageData(
      0,
      0,
      canvas.width,
      canvas.height
    );
    return imageData.data;
  }
  return new Uint8ClampedArray();
};

const countDarkLightPixels = (
  imagePixels: Uint8ClampedArray
): { darkPixels: number; lightPixels: number } => {
  let darkPixels = 0;
  let lightPixels = 0;

  for (
    let x = 0, imageDataLength = imagePixels.length;
    x < imageDataLength;
    x += RGBA_NUMBER
  ) {
    const alphaChannel = imagePixels[x + ALPHA_INDEX];
    if (alphaChannel === 0) {
      continue;
    }

    const red = imagePixels[x + RED_INDEX];
    const green = imagePixels[x + GREEN_INDEX];
    const blue = imagePixels[x + BLUE_INDEX];
    const lightness = RGBToHSL(red, green, blue)[LIGHTNESS_INDEX];

    if (lightness > LIGHTNESS_LEVEL) {
      lightPixels += 1;
    } else {
      darkPixels += 1;
    }
  }

  return { darkPixels, lightPixels };
};

export const isLightImage = (imageSrc: string): Promise<boolean> => {
  const newImage = createImage(imageSrc);

  return new Promise(resolve => {
    newImage.addEventListener("load", () => {
      const canvas = createCanvas(newImage.height, newImage.width);
      const imagePixels = getImagePixelsFromCanvas(canvas, newImage);
      const { darkPixels, lightPixels } = countDarkLightPixels(imagePixels);

      if (lightPixels > darkPixels) {
        resolve(true);
      }
      resolve(false);
    });

    newImage.addEventListener("error", () => {
      resolve(false);
    });
  });
};
