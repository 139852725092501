"use client";

import { Carousel } from "antd";
import { CarouselRef } from "antd/lib/carousel";
import classNames from "classnames";
import React, { useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import CollegeLogo from "rivals/components/shared/CollegeLogo";
import Chevron from "rivals/components/shared/Icons/Chevron";
import TooltipWrapper from "rivals/components/shared/Tooltip";
import EmptyStateMessage from "rivals/components/shared/Widgets/EmptyStateMessage";
import TopTarget from "rivals/components/shared/Widgets/TopTargets/TopTarget";
import WidgetHeader from "rivals/components/shared/Widgets/WidgetHeader";
import WidgetLayout from "rivals/components/shared/Widgets/WidgetLayout";
import { ROTATE_90 } from "rivals/shared/constants";
import { WIDGET_SIZE } from "rivals/shared/interfaces/Widgets";
import { setData } from "rivals/shared/utils/qa";
import styles from "./TopTargetsWidget.module.scss";
import strings from "./strings";
import { Props } from "./types";

function TopTargetsWidget({
  condensedView = false,
  darkMode = false,
  externalLink = false,
  primaryColor,
  selectedSiteShortname,
  logoUrl,
  reflink,
  removeBorder = false,
  showTopBar = true,
  size = WIDGET_SIZE.MEDIUM,
  sponsor,
  topTargets = [],
  width,
  year
}: Props): React.JSX.Element {
  const philter = "top-targets-widget";
  const tooltipOverlayStyles = {
    maxWidth: "197px",
    padding: "10px"
  };
  const [slideIndex, setSlideIndex] = useState(0);
  const carouselRef = useRef<CarouselRef | null>(null);
  const LAST_SLIDE_INDEX = topTargets.length;
  const toolTipText = (
    <div className={styles.toolTipText}>
      <span className={styles.toolTipBold}>Top Targets</span> are unsigned
      prospects with a recent forecast trend for {selectedSiteShortname}.
    </div>
  );

  const tooltip = (
    <TooltipWrapper
      className={styles.tooltipStyles}
      overlayInnerStyle={tooltipOverlayStyles}
      placement="left"
      title={toolTipText}
      toggleIcon={
        darkMode && (
          <img
            alt="question circle light"
            src={"/static/icons/icons_question_circle_bone.svg"}
          />
        )
      }
    />
  );
  const titleText = strings.title;
  const collegeLogo = (
    <CollegeLogo
      collegeLogo={logoUrl}
      collegeName={selectedSiteShortname}
      collegeSiteColor={primaryColor}
      logoSize={36}
    />
  );
  // Since top targets widget condensed view does not have padding, we need to add padding to the main title to match others
  const mainTitleStyles = condensedView ? { padding: "0 8px" } : {};
  const title = (
    <WidgetHeader
      actionIcon={tooltip}
      anchorActionIconTopRight
      eyebrowText="FUTURECAST"
      mainTitleStyles={mainTitleStyles}
      noWrapTitle={true}
      title={titleText}
      titleIcon={collegeLogo}
      year={year ? year.toString() : ""}
    />
  );
  const beforeSlideChange = (_: number, next: number): void => {
    setSlideIndex(next);
  };

  const next = (): void => {
    carouselRef?.current?.next();
  };

  const previous = (): void => {
    carouselRef?.current?.prev();
  };

  const atTop = slideIndex <= 0;
  const atBottom = slideIndex + size >= LAST_SLIDE_INDEX;
  const numberSlidesMissing = size - LAST_SLIDE_INDEX;

  const moreTargets = (
    <div className={styles.buttonText}>{strings.moreTargets}</div>
  );

  const chevron = (
    direction: number,
    fill: string = styles.bone
  ): React.JSX.Element => (
    <div className={styles.buttonContent}>
      {moreTargets}
      <div className={styles.buttonIcon}>
        <Chevron fill={fill} rotate={direction} />
      </div>
      <div />
    </div>
  );

  let topTargetSlides = topTargets.map(topTarget => {
    return (
      <li key={topTarget.id}>
        <TopTarget
          activeCollege={selectedSiteShortname}
          condensedView={condensedView}
          darkMode={darkMode}
          externalLink={externalLink}
          prospect={topTarget}
          reflink={reflink}
        />
      </li>
    );
  });

  // If number of slides displayed is less than the desired display amount, fill remainder with blank slides
  if (numberSlidesMissing > 0) {
    topTargetSlides = [
      ...topTargetSlides,
      ...Array(numberSlidesMissing).fill(
        <li key={`empty-card-${uuidv4()}`}>
          <TopTarget
            activeCollege={selectedSiteShortname}
            condensedView={condensedView}
            darkMode={darkMode}
            externalLink={externalLink}
          />
        </li>
      )
    ];
  }

  const topTargetsList = topTargets && (
    <ul
      className={styles.topTargetsList}
      {...setData(philter, "prospect-list")}
    >
      <div className={styles.topButtonWrapper}>
        <button
          className={classNames(styles.scrollButton, {
            [styles.standardHeightScrollButton]: condensedView,
            [styles.disabled]: atTop
          })}
          disabled={atTop}
          onClick={previous}
          type="button"
        >
          {chevron(ROTATE_90, atTop ? styles.abyss50 : styles.bone)}
        </button>
      </div>
      <Carousel
        beforeChange={beforeSlideChange}
        className={classNames({
          [styles.condensedSlider]: condensedView
        })}
        dots={false}
        ref={carouselRef}
        slidesToShow={size}
        swipeToSlide
        vertical
      >
        {topTargetSlides}
      </Carousel>
      <div className={styles.bottomButtonWrapper}>
        <button
          className={classNames(styles.scrollButton, {
            [styles.standardHeightScrollButton]: condensedView,
            [styles.disabled]: atBottom
          })}
          disabled={atBottom}
          onClick={next}
          type="button"
        >
          {chevron(-ROTATE_90, atBottom ? styles.abyss50 : styles.bone)}
        </button>
      </div>
    </ul>
  );

  if (!topTargets || topTargets.length < 1) {
    return (
      <WidgetLayout
        darkMode={darkMode}
        removeBorder={removeBorder}
        sponsor={sponsor}
        title={title}
        topBorderColor={showTopBar ? `#${primaryColor}` : undefined}
        width={width}
      >
        <div className={styles.topTargetsWidgetWrapper} {...setData(philter)}>
          <EmptyStateMessage
            darkMode={darkMode}
            message={strings.emptyState(selectedSiteShortname)}
            philter={philter}
          />
        </div>
      </WidgetLayout>
    );
  }

  return (
    <WidgetLayout
      darkMode={darkMode}
      removeBorder={removeBorder}
      sidePadding={condensedView ? 0 : undefined}
      sponsor={sponsor}
      title={title}
      topBorderColor={showTopBar ? `#${primaryColor}` : undefined}
      width={width}
    >
      <div {...setData(philter)}>{topTargetsList}</div>
    </WidgetLayout>
  );
}

export default TopTargetsWidget;
