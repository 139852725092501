import classNames from "classnames";
import { setData } from "rivals/shared/utils/qa";
import styles from "./WidgetHeader.module.scss";
import { Props } from "./types";

const WidgetHeader = ({
  actionIcon,
  anchorActionIconTopRight = false,
  eyebrowText,
  leftPadding = 0,
  mainTitleStyles,
  philter = "",
  subheaderText,
  titleIcon,
  titleLogo,
  title,
  noWrapTitle = false,
  year = ""
}: Props): React.JSX.Element => {
  const titleStyles = classNames(
    {
      [styles.noWrap]: noWrapTitle
    },
    styles.mainTitleText,
    styles.inlineTitleContainer
  );
  const textTitle = (
    <>
      {eyebrowText && <div className={styles.eyebrowText}>{eyebrowText}</div>}
      <div className={titleStyles}>
        {year && <span className={styles.headerYear}>{year}</span>}
        <span>{title}</span>
      </div>
    </>
  );

  return (
    <div
      style={{ paddingLeft: `${leftPadding}px` }}
      {...setData(philter, "widget-header")}
    >
      <div className={styles.mainTitle} style={mainTitleStyles}>
        {titleIcon && (
          <div
            className={styles.titleIcon}
            {...setData(philter, "widget-icon")}
          >
            {titleIcon}
          </div>
        )}
        <div
          className={styles.widgetTitle}
          {...setData(philter, "widget-title")}
        >
          {titleLogo ? (
            <div className={styles.titleLogo}>{titleLogo}</div>
          ) : (
            textTitle
          )}
          {subheaderText && (
            <div className={styles.secondaryTitleText}>{subheaderText}</div>
          )}
        </div>
        {actionIcon && (
          <div
            className={classNames(styles.actionIcon, {
              [styles.actionIconAnchoredTopRight]: anchorActionIconTopRight
            })}
          >
            {actionIcon}
          </div>
        )}
      </div>
    </div>
  );
};

export default WidgetHeader;
