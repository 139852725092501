import classNames from "classnames";
import { setData } from "rivals/shared/utils/qa";
import styles from "./EmptyStateMessage.module.scss";
import { Props } from "./types";

const EmptyStateMessage = ({
  message,
  philter = "",
  darkMode
}: Props): React.JSX.Element => {
  return (
    <div
      className={classNames(styles.emptyState, {
        [styles.darkMode]: darkMode
      })}
      {...setData(philter, "empty-state")}
    >
      <span>{message}</span>
    </div>
  );
};
export default EmptyStateMessage;
