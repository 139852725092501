"use client";

import classNames from "classnames";
import Image from "next/image";
import React from "react";
import MediaWrapper from "rivals/components/shared/Media";
import { SHAPES } from "rivals/shared/constants";
import { imageLoader } from "rivals/shared/utils/nextImage";
import { setData } from "rivals/shared/utils/qa";
import styles from "./Avatar.module.scss";
import DefaultAvatar from "./DefaultAvatar";
import { Height, Props } from "./types";
import { calcWidth } from "./utils";

/**
 * The Avatar is used to display the profile image for a prospect, college player, forecaster, author, etc.
 * Any model that has a profile image associated with it in the db.
 *
 * When there is no image url, it will render a default avatar.
 *
 * Circle is default. Ratio for rectangle avatar is 3:4.
 */

const DEFAULT_SIZE = 200;

const Avatar = ({
  alt = "",
  className,
  grayscale = false,
  mobileBreakpoint,
  mobileSize,
  philter = "",
  priority,
  shape = SHAPES.CIRCLE,
  size = DEFAULT_SIZE,
  url
}: Props): React.JSX.Element => {
  const classNameShape =
    shape === SHAPES.CIRCLE ? styles.circle : styles.rectangle;

  const nextImage = (height: Height): React.JSX.Element => {
    if (url) {
      return (
        <Image
          alt={`${shape} avatar ${alt}`}
          className={classNames(className, classNameShape)}
          height={height}
          loader={imageLoader}
          priority={priority}
          role="img"
          src={url}
          title="avatar"
          width={calcWidth(height, shape)}
          {...setData("avatar", philter)}
        />
      );
    } else {
      return (
        <DefaultAvatar
          className={classNames(className, classNameShape)}
          grayscale={grayscale}
          philter={philter}
          shape={shape}
          size={height}
        />
      );
    }
  };

  if (mobileSize && mobileBreakpoint) {
    return (
      <>
        <MediaWrapper lessThan={mobileBreakpoint}>
          {nextImage(mobileSize)}
        </MediaWrapper>
        <MediaWrapper greaterThanOrEqual={mobileBreakpoint}>
          {nextImage(size)}
        </MediaWrapper>
      </>
    );
  } else {
    return nextImage(size);
  }
};

export default Avatar;
