import { ReactElement } from "react";
import styles from "rivals/components/shared/Icons/Icons.module.scss";
import { IconProps } from "./iconPropsTypes";

const Unknown = ({ height, width }: IconProps): ReactElement => (
  <svg
    fill="none"
    height={height || "100%"}
    role="graphics-document"
    viewBox="0 0 35 35"
    width={width || "100%"}
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Unknown Icon</title>
    <circle cx="17.5" cy="17.5" fill={styles.gray85} r="17.5" />
    <path
      d="M13.0547 9.41745C14.0711 8.87203 15.5586 8.42578 17.7155 8.42578C21.112 8.42578 23.368 9.98766 23.368 13.0866C23.368 15.2187 22.4507 16.5079 20.6161 17.8714C19.6989 18.5904 19.2278 19.0614 19.2278 20.0531V20.4745H15.2859V19.9043C15.2859 17.8962 15.9801 16.9789 17.4676 15.7889C18.4345 15.0204 18.9055 14.4997 18.9055 13.5081C18.9055 12.6404 18.3105 11.9958 16.9718 11.9958C15.3107 11.9958 14.2943 12.4172 13.2282 13.037L13.0547 12.9379V9.41745ZM17.2445 26.5237C15.633 26.5237 14.8149 25.6312 14.8149 24.1437C14.8149 22.6562 15.633 21.7637 17.2445 21.7637C18.8559 21.7637 19.6493 22.6562 19.6493 24.1437C19.6493 25.6312 18.8559 26.5237 17.2445 26.5237Z"
      fill={styles.gray50}
    />
  </svg>
);

export default Unknown;
