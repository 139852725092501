import classNames from "classnames";
import React from "react";
import Avatar from "rivals/components/shared/Avatar";
import CollegeLogo from "rivals/components/shared/CollegeLogo";
import RivalsLink from "rivals/components/shared/Link";
import ProspectRank from "rivals/components/shared/ProspectRank";
import Stars from "rivals/components/shared/Stars";
import { buildRefLink } from "rivals/services/referral";
import { RANK_TITLES } from "rivals/shared/constants";
import { setData } from "rivals/shared/utils/qa";
import styles from "./TopTarget.module.scss";
import strings from "./strings";
import { Props } from "./types";

function TopTarget({
  activeCollege,
  condensedView,
  darkMode,
  externalLink = false,
  prospect,
  reflink
}: Props): React.JSX.Element {
  if (!prospect) {
    const philter = "empty-target";
    return (
      <div
        className={classNames(styles.targetBody, {
          [styles.darkMode]: darkMode,
          [styles.condensed]: condensedView
        })}
        {...setData(philter)}
      >
        <div className={styles.prospectData}>
          <div
            className={classNames(styles.prospectBioData, styles.emptyStyles, {
              [styles.condensed]: condensedView
            })}
          >
            <Avatar alt="empty target" grayscale={true} priority size={40} />
            <div {...setData(philter, "prospect-data")}>
              <div className={styles.emptyFirstName} />
              <div
                className={classNames(styles.emptyLastName, {
                  [styles.condensed]: condensedView
                })}
              />
            </div>
          </div>
          <div
            className={classNames(styles.prospectStats, {
              [styles.condensed]: condensedView
            })}
            {...setData(philter, "rankings")}
          >
            <div className={styles.emptyStats} />
            <div className={styles.emptyStats} />
            <div className={styles.emptyStats} />
            {!condensedView && <div className={styles.emptyStats} />}
          </div>
        </div>
        <div
          className={classNames(styles.forecastSpread, {
            [styles.darkMode]: darkMode
          })}
        >
          <div
            className={styles.forecastedColleges}
            {...setData(philter, "forecasts")}
          />
        </div>
      </div>
    );
  }

  const {
    athleteUrl,
    firstName,
    forecastSpread,
    id,
    lastName,
    nationalRank,
    nationalRankChange,
    nationalRankUrl,
    positionAbbreviation,
    positionRank,
    positionRankChange,
    positionRankUrl,
    profileImageUrl,
    rivalsRating,
    stars,
    stateRank,
    stateRankChange,
    stateRankUrl
  } = prospect;

  // For condensed view, only show max 2 forecasts
  const FORECAST_SPREAD = condensedView ? 3 : 2; // eslint-disable-line no-magic-numbers
  const condensedForecasts = forecastSpread.slice(0, 2); // eslint-disable-line no-magic-numbers
  const forecasts = condensedView ? condensedForecasts : forecastSpread;
  const philter = `top-target-${id}`;

  const ForecastPercentage = (
    collegeName: string,
    collegeSiteColor: string,
    percentage: string,
    collegeLogoUrl?: string
  ): React.JSX.Element => {
    return (
      <div
        className={classNames(styles.forecast, {
          [styles.maxForecasts]: forecasts.length === FORECAST_SPREAD
        })}
      >
        <CollegeLogo
          collegeLogo={collegeLogoUrl}
          collegeName={collegeName}
          collegeSiteColor={collegeSiteColor}
          logoSize={30}
        />
        <div
          className={classNames({
            [styles.activeCollege]: collegeName == activeCollege
          })}
        >
          {percentage}
        </div>
      </div>
    );
  };

  const prospectStars = (
    <Stars
      className={classNames(styles.athleteStars, {
        [styles.condensed]: condensedView
      })}
      numStars={stars}
      size="small"
    />
  );

  // Only show National rank for condensed view
  const prospectRanks = condensedView ? (
    <ProspectRank
      changeValue={nationalRankChange}
      philter={philter}
      rank={nationalRank}
      rankTitle={RANK_TITLES.NATIONAL}
      rankUrl={nationalRankUrl}
    />
  ) : (
    <>
      <ProspectRank
        changeValue={stateRankChange}
        philter={philter}
        rank={stateRank}
        rankTitle={RANK_TITLES.STATE}
        rankUrl={stateRankUrl}
      />
      <ProspectRank
        changeValue={nationalRankChange}
        philter={philter}
        rank={nationalRank}
        rankTitle={RANK_TITLES.NATIONAL}
        rankUrl={nationalRankUrl}
      />
      <ProspectRank
        changeValue={positionRankChange}
        philter={philter}
        rank={positionRank}
        rankTitle={RANK_TITLES.POSITION}
        rankUrl={positionRankUrl}
      />
    </>
  );

  return (
    <div
      className={classNames(styles.targetBody, {
        [styles.darkMode]: darkMode,
        [styles.condensed]: condensedView
      })}
      {...setData(philter)}
    >
      <div className={styles.prospectData}>
        <RivalsLink
          className={classNames(styles.prospectBioData, {
            [styles.condensed]: condensedView
          })}
          href={buildRefLink(athleteUrl, reflink)}
          target={externalLink ? "_blank" : undefined}
        >
          <Avatar alt={strings.altText} size={40} url={profileImageUrl} />
          <div {...setData(philter, "prospect-data")}>
            <div>{firstName}</div>
            <div
              className={classNames(styles.lastName, {
                [styles.condensed]: condensedView
              })}
            >
              {lastName}
            </div>
            <div className={styles.position}>{positionAbbreviation}</div>
          </div>
        </RivalsLink>
        <div
          className={classNames(styles.prospectStats, {
            [styles.condensed]: condensedView
          })}
          {...setData(philter, "rankings")}
        >
          <div>
            <div className={styles.rank}>{rivalsRating}</div>
            {!condensedView && prospectStars}
          </div>
          {condensedView && <div>{prospectStars}</div>}
          {prospectRanks}
        </div>
      </div>
      <div
        className={classNames(styles.forecastSpread, {
          [styles.darkMode]: darkMode
        })}
      >
        <div
          className={styles.forecastedColleges}
          {...setData(philter, "forecasts")}
        >
          {forecasts.map(forecast => {
            return (
              <div key={forecast.name}>
                {ForecastPercentage(
                  forecast.name,
                  forecast.primaryColor,
                  forecast.percentage,
                  forecast.logo
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default TopTarget;
