import classNames from "classnames";
import { setData } from "rivals/shared/utils/qa";
import styles from "./WidgetLayout.module.scss";
import strings from "./strings";
import { DEFAULT_SIDE_PADDING, Props } from "./types";

const WidgetLayout = ({
  button,
  buttonFullWidth = false,
  children,
  darkMode = false,
  philter = "",
  removeBorder,
  sidePadding = DEFAULT_SIDE_PADDING,
  sponsor,
  title,
  topBorderColor,
  width
}: Props): JSX.Element => {
  const buttonStyles = classNames(
    {
      [styles.buttonFullWidth]: buttonFullWidth
    },
    styles.buttonContainer
  );

  const layoutStyles = width
    ? { maxWidth: `${width}px`, width: `${width}px` }
    : {};

  const titleStyles = classNames(
    {
      [styles.sponsorTitleContainer]: sponsor
    },
    styles.titleContainer
  );

  return (
    <div
      className={classNames(styles.container, {
        [styles.darkMode]: darkMode,
        [styles.noBorder]: removeBorder
      })}
      style={layoutStyles}
      {...setData("widget", philter)}
    >
      {sponsor}
      {topBorderColor && !sponsor && (
        <div
          style={{
            borderTop: strings.borderTop(topBorderColor),
            position: "absolute",
            top: 0,
            width: "100%"
          }}
        />
      )}
      <div className={styles.body} style={{ padding: `0 ${sidePadding}px` }}>
        <div className={titleStyles}>{title}</div>
        {children}
        {button && !buttonFullWidth && (
          <div className={buttonStyles}>{button}</div>
        )}
      </div>
      {button && buttonFullWidth && (
        <div className={buttonStyles}>{button}</div>
      )}
    </div>
  );
};

export default WidgetLayout;
