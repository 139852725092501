import { ReactElement } from "react";
import styles from "rivals/components/shared/Icons/Icons.module.scss";
import { ICON_SIZE_6, ICON_SIZE_8 } from "./constants";
import type { IconProps } from "./iconPropsTypes";

const RankDown = ({
  fill = styles.eyeBlack50,
  height = ICON_SIZE_8,
  width = ICON_SIZE_6
}: IconProps): ReactElement => (
  <svg
    fill="none"
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3182_6117)">
      <path d="M6.17043 4.42516H0L10 12V0L6.17043 4.42516Z" fill={fill} />
    </g>
    <title>rank down</title>
  </svg>
);

export default RankDown;
