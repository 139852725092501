"use client";

import classNames from "classnames";
import Image from "next/image";
import { CSSProperties, useState } from "react";
import Unknown from "rivals/components/shared/Icons/Unknown";
import MediaWrapper from "rivals/components/shared/Media";
import { isLightImage } from "rivals/shared/utils/image";
import { imageLoader } from "rivals/shared/utils/nextImage";
import styles from "./CollegeLogo.module.scss";
import GenericCollegeLogo from "./GenericCollegeLogo";
import { DEFAULT_SIZE, Props, Size } from "./types";

// Ratios used to determine size and placement of the overlayIcon based on the logoSize
const logoSizeRatio = 0.44;
const bottomPositionRatio = 0.11;
const rightPositionRatio = 0.17;

const CollegeLogo = ({
  applyDarkBackground = false,
  applyLightBackground = false,
  collegeLogo,
  collegeName = "",
  collegeSiteColor,
  dataPhilter,
  logoSize = DEFAULT_SIZE,
  mobileBreakpoint,
  mobileSize,
  overlayIcon,
  priority
}: Props): JSX.Element | null => {
  let logo: JSX.Element | null = null;
  const overlayIconStyles: CSSProperties = logoSize
    ? {
        bottom: `-${logoSize * bottomPositionRatio}px`,
        height: `${logoSize * logoSizeRatio}px`,
        right: `-${logoSize * rightPositionRatio}px`,
        width: `${logoSize * logoSizeRatio}px`
      }
    : {};

  const [isLightLogo, setIsLightLogo] = useState(false);
  const [imageLoadError, setImageLoadError] = useState(false);

  const logoElement = (size: Size): JSX.Element => {
    if (collegeLogo && collegeName && !imageLoadError) {
      const onLogoLoad = (): void => {
        if (applyDarkBackground || applyLightBackground) {
          isLightImage(collegeLogo).then(lightImage => {
            setIsLightLogo(lightImage);
          });
        }
      };

      logo = (
        <Image
          alt={collegeName}
          className={styles.logo}
          data-philter={dataPhilter}
          height={size}
          loader={imageLoader}
          onError={() => setImageLoadError(true)}
          onLoad={onLogoLoad}
          priority={priority}
          role="img"
          src={collegeLogo}
          title={collegeName}
          unoptimized
          width={size}
        />
      );
    } else if (collegeName && (imageLoadError || !collegeLogo)) {
      logo = (
        <GenericCollegeLogo
          collegeName={collegeName}
          collegeSiteColor={collegeSiteColor}
          logoSize={size}
        />
      );
    } else {
      logo = <Unknown height={size?.toString()} width={size?.toString()} />;
    }

    return (
      <div
        className={classNames({
          [styles.darkBG]: isLightLogo && applyDarkBackground,
          [styles.lightBG]: !isLightLogo && applyLightBackground,
          [styles.hasOverlayIcon]: overlayIcon
        })}
        style={{
          height: `${size}px`,
          width: `${size}px`
        }}
      >
        {logo}
        {overlayIcon && (
          <div className={styles.overlayIcon} style={overlayIconStyles}>
            {overlayIcon}
          </div>
        )}
      </div>
    );
  };

  if (mobileSize && mobileBreakpoint) {
    return (
      <>
        <MediaWrapper lessThan={mobileBreakpoint}>
          {logoElement(mobileSize)}
        </MediaWrapper>
        <MediaWrapper greaterThanOrEqual={mobileBreakpoint}>
          {logoElement(logoSize)}
        </MediaWrapper>
      </>
    );
  } else {
    return logoElement(logoSize);
  }
};

export default CollegeLogo;
