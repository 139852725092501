import { CSSProperties, ReactElement } from "react";
import { DEFAULT_SIZE } from "rivals/components/shared/Avatar/types";
import { calcWidth } from "rivals/components/shared/Avatar/utils";
import { SHAPES } from "rivals/shared/constants";
import { setData } from "rivals/shared/utils/qa";
import styles from "rivals/styles/exports.module.scss";
import { Props } from "./types";

const DefaultAvatar = ({
  className,
  grayscale = false,
  philter,
  size = DEFAULT_SIZE,
  shape = SHAPES.CIRCLE
}: Props): ReactElement => {
  const avatarColors = grayscale
    ? {
        backgroundColor: styles.mud,
        fill: styles.eyeBlack50
      }
    : { backgroundColor: styles.blue95, fill: styles["commitment-blue"] };
  const wrapperStyles: CSSProperties = {
    backgroundColor: avatarColors.backgroundColor,
    height: `${size}px`,
    overflow: "clip",
    width: `${calcWidth(size, shape)}px`
  };

  if (shape === SHAPES.CIRCLE) {
    wrapperStyles.borderRadius = "100px";
  }

  return (
    <div
      className={className}
      style={wrapperStyles}
      {...setData("default-avatar", philter)}
    >
      <svg
        height={size}
        role="img"
        viewBox={shape == SHAPES.CIRCLE ? "0 0 200 200" : "0 0 150 200"}
        width={calcWidth(size, shape)}
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Default Avatar</title>

        {shape == SHAPES.RECTANGLE && (
          <path
            d="M150 200.5V158.58C150 158.58 140.786 146.692 130.098 141.394C119.41 136.096 118.894 136.512 113.248 134.053C107.197 131.415 101.197 128.784 95.1744 126.08C94.4805 125.772 93.935 125.2 93.656 124.49C92.9926 122.038 92.1818 119.549 92.1007 117.059C91.9017 112.601 90.4939 107.979 93.2506 103.618C96.1327 99.0557 95.6167 93.2823 98.3366 88.512C99.5749 86.3423 99.5233 83.3702 99.8108 80.7399C99.9214 79.8482 99.6634 78.4216 99.0737 78.0501C98.1744 77.4854 98.3366 76.9727 98.3366 76.2296C98.5135 72.0538 98.8305 67.8779 98.7715 63.7095C98.7199 59.8458 98.2408 56.0117 96.0442 52.616C89.3219 42.1021 75.5897 38.0006 64.3563 43.3355C55.6658 47.4668 50.5577 54.117 50.86 64.2148C50.9779 68.1975 51.1622 72.1875 51.2801 76.1702C51.2801 76.6829 51.2801 77.5225 50.9705 77.6563C49.4963 78.3473 49.7248 79.5882 49.887 80.7547C50.2039 82.9838 50.5651 85.2129 51.0295 87.442C51.2285 88.4154 51.457 89.6711 52.1204 90.2061C53.344 91.2464 53.4914 92.5318 53.6683 93.8767C54.2006 98.2117 55.5704 102.4 57.7002 106.204C57.9359 106.706 58.064 107.253 58.0762 107.809C58.5479 113.589 56.602 119.058 55.9312 124.705C55.8722 125.225 54.9214 125.82 54.3096 126.087C42.7518 131.184 30.5897 136.289 19.5332 141.223C8.47666 146.157 0 158.58 0 158.58V200.5H150Z"
            fill={avatarColors.fill}
          />
        )}
        {shape == SHAPES.CIRCLE && (
          <path
            d="M173.464 200H200C200 200 187.715 184.126 173.464 177.052C159.214 169.978 158.526 170.533 150.998 167.249C142.929 163.727 134.929 160.215 126.899 156.604C125.974 156.192 125.247 155.429 124.875 154.48C123.99 151.206 122.909 147.883 122.801 144.559C122.536 138.606 120.658 132.435 124.334 126.611C128.177 120.519 127.489 112.81 131.115 106.441C132.767 103.544 132.698 99.5752 133.081 96.0631C133.229 94.8725 132.885 92.9676 132.098 92.4715C130.899 91.7175 131.115 91.0329 131.115 90.0407C131.351 84.4649 131.774 78.8891 131.695 73.3231C131.627 68.164 130.988 63.0445 128.059 58.5104C119.096 44.4716 100.786 38.995 85.8083 46.1186C74.2211 51.6349 67.4103 60.5146 67.8133 73.9978C67.9705 79.3157 68.2162 84.6435 68.3735 89.9614C68.3735 90.6459 68.3735 91.7671 67.9607 91.9457C65.9951 92.8684 66.2998 94.5252 66.516 96.0829C66.9386 99.0593 67.4201 102.036 68.0393 105.012C68.3047 106.312 68.6093 107.989 69.4939 108.703C71.1253 110.092 71.3219 111.808 71.5577 113.604C72.2675 119.393 74.0939 124.984 76.9337 130.064C77.2478 130.735 77.4186 131.465 77.4349 132.207C78.0639 139.926 75.4693 147.228 74.5749 154.768C74.4963 155.463 73.2285 156.256 72.4128 156.614C57.0025 163.42 40.7862 170.236 26.0442 176.824C11.3022 183.411 0 200 0 200H26.0442H173.464Z"
            fill={avatarColors.fill}
          />
        )}
      </svg>
    </div>
  );
};

export default DefaultAvatar;
