"use client";

import Image from "next/image";
import { imageLoader } from "rivals/shared/utils/nextImage";
import { setData } from "rivals/shared/utils/qa";
import styles from "./Sponsor.module.scss";
import { Props } from "./types";

const Sponsor = ({ sponsor }: Props): JSX.Element | null => {
  if (!sponsor) {
    return null;
  }

  return (
    <div
      className={styles.sponsorContainer}
      style={{ backgroundColor: `#${sponsor?.backgroundColor}` }}
    >
      <Image
        alt="sponsored image"
        className={styles.sponsorImage}
        height={0}
        loader={imageLoader}
        src={sponsor.logo}
        style={{ height: "100%", width: "auto" }}
        title="sponsored by"
        width={0}
        {...setData("sponsor")}
      />
    </div>
  );
};

export default Sponsor;
