import { MEDIA_BREAKPOINTS } from "rivals/media";
import { SHAPES } from "rivals/shared/constants";

/* eslint-disable no-magic-numbers */
export type AvatarSize = 40 | 60 | 64 | 80 | 92 | 120 | 138 | 142 | 200 | 240;
export type AvatarSizeMobile = 54 | 64 | 86 | 93 | 142;
/* eslint-enable no-magic-numbers */

export type Height = AvatarSize | AvatarSizeMobile;
export const DEFAULT_SIZE = 200;

export interface Props {
  /**
   * String to be appended to "avatar" alt.
   */
  alt: string;
  /**
   * Assigned to root element's className prop.
   */
  className?: string;
  /**
   * Use the black and gray version of the avatar
   */
  grayscale?: boolean;
  /**
   * Breakpoint at which the mobileSize should be applied.
   */
  mobileBreakpoint?: MEDIA_BREAKPOINTS;
  /**
   * Size of avatar in pixels to be rendered at a mobile smaller breakpoint.
   * Used as height & width for circle or height for rectangle - width is calculated using a 3:4 ratio.
   */
  mobileSize?: AvatarSizeMobile;
  /**
   * String to be appended to "avatar" data-philter. Should be specific to the page or section.
   */
  philter?: string;
  /**
   * Set to true when the image is above the fold and should be preloaded.
   * https://nextjs.org/docs/pages/api-reference/components/image#priority
   */
  priority?: boolean;
  /**
   * Shape of avatar - SHAPES.CIRCLE or SHAPES.RECTANGLE
   * @default SHAPES.CIRCLE
   */
  shape?: SHAPES;
  /**
   * Size of avatar in pixels.
   * Used as height & width for circle or height for rectangle - width is calculated using a 3:4 ratio.
   * @default 200
   */
  size?: AvatarSize;
  /**
   * URL for the avatar
   */
  url?: string;
}
