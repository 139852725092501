import classNames from "classnames";
import { ROTATE_90 } from "rivals/shared/constants";
import styles from "./Chevron.module.scss";
import { Props } from "./types";

const Chevron = ({
  animateRotation = false,
  fill,
  height = "6",
  rotate = ROTATE_90,
  title = "",
  width = "10"
}: Props): JSX.Element => (
  <svg
    alignmentBaseline="middle"
    height={height}
    role="graphics-document"
    version="1.1"
    viewBox="0 0 10 6"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{title}</title>
    <g
      fill="none"
      fillRule="evenodd"
      id="Dropdowns"
      stroke="none"
      strokeWidth="1"
    >
      <g
        fill={fill || styles.black}
        id="dropdown"
        transform="translate(-329.000000, -18.000000)"
      >
        <g
          id="icn-/-chevron-/-downward-/-small"
          transform="translate(318.000000, 5.000000)"
        >
          <polygon
            className={classNames({
              [styles.transitionAnimation]: animateRotation
            })}
            id="svg"
            points="18.7071068 19.2928932 17.2928932 20.7071068 12.5857864 16 17.2928932 11.2928932 18.7071068 12.7071068 15.4142136 16"
            transform={`translate(15.646447, 16.000000) rotate(${rotate}) translate(-15.646447, -16.000000)`}
          ></polygon>
        </g>
      </g>
    </g>
  </svg>
);

export default Chevron;
