/* eslint-disable no-magic-numbers */
export type WidgetWidth = 200 | 250 | 300 | undefined;
export type SidePadding = 0 | 8;
/* eslint-enable no-magic-numbers */

export const DEFAULT_SIDE_PADDING = 8;
export const STANDARD_WIDTH = 300;

export interface Props {
  /**
   * Button element shown at the bottom of widget.
   */
  button?: JSX.Element;
  /**
   * Removes bottom and side padding on the bottom button, if it exists
   */
  buttonFullWidth?: boolean;
  /**
   * Children element that make up main body of the widget.
   */
  children: JSX.Element;
  /**
   * Defines base color palate
   */
  darkMode?: boolean;
  /**
   * String to be appended to "widget" data-philter
   */
  philter?: string;
  /**
   * Set to true to remove border
   */
  removeBorder?: boolean;
  /**
   * Number of pixels for left and right padding that is applied to the WidgetLayout.
   * 8 is the max and default for now until other sizes are established.
   */
  sidePadding?: SidePadding;
  /**
   * Indicates the presence of a sponsor that has provided a logo and possibly background color
   * to be displayed in the top 20px of the widget
   */
  sponsor?: JSX.Element;
  /**
   * Title element shown at the top of the widget.
   */
  title: JSX.Element;
  /**
   * Adds a 8px top border to the widget of the passed color.
   */
  topBorderColor?: string;
  /**
   * Width of widget to be set in pixels. Defaults to 100% if undefined.
   */
  width?: WidgetWidth;
}
