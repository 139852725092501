import { ReactElement } from "react";
import { MEDIA_BREAKPOINTS } from "rivals/media";

/* eslint-disable no-magic-numbers */
export type LogoSize =
  | 24
  | 30
  | 32
  | 36
  | 40
  | 48
  | 60
  | 72
  | 80
  | 100
  | 300
  | 360;
export type LogoSizeMobile = 30 | 40 | 50;
/* eslint-enable no-magic-numbers */

export type Size = LogoSize | LogoSizeMobile;
export const DEFAULT_SIZE = 36;

export interface Props {
  /**
   * Flag to force enable/disable dark background on light images
   */
  applyDarkBackground?: boolean;
  /**
   * Flag to force enable/disable light background on dark images
   */
  applyLightBackground?: boolean;
  /**
   * Logo image src URL
   */
  collegeLogo?: string;
  /**
   * College or Site name
   */
  collegeName?: string;
  /**
   * College or Site primary or secondary color
   */
  collegeSiteColor?: string;
  /**
   * Data-philter attribute for QA automation
   */
  dataPhilter?: string;
  /**
   * Size of college logo in pixels.
   * @default 36
   */
  logoSize: LogoSize;
  /**
   * Breakpoint at which the mobileSize should be applied.
   */
  mobileBreakpoint?: MEDIA_BREAKPOINTS;
  /**
   * Size of college logo in pixels to be rendered at a mobile smaller breakpoint.
   * Used as height & width for circle or height for rectangle - width is calculated using a 3:4 ratio.
   */
  mobileSize?: LogoSizeMobile;
  /**
   * Icon that slightly overlays the bottom right corner of the college logo. This requires logoSize to be passed.
   */
  overlayIcon?: ReactElement;
  /**
   * Set to true when the image is above the fold and should be preloaded.
   * https://nextjs.org/docs/pages/api-reference/components/image#priority
   */
  priority?: boolean;
  /**
   * When using NextImage with fill, pass in size as well for performance
   * https://nextjs.org/docs/pages/api-reference/components/image#sizes
   */
  sizes?: string;
}
