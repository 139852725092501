import Tooltip from "antd/lib/tooltip";
import classNames from "classnames";
import colors from "rivals/styles/exports.module.scss";
import styles from "./Tooltip.module.scss";
import type { Props } from "./types";

const DefaultToggleIcon = (props: { className?: string }): JSX.Element => (
  <img
    alt="question circle"
    className={classNames(styles.tooltipTrigger, props.className)}
    src="/static/icons/question-circle.svg"
    {...props}
  />
);

const invertedStyles = {
  color: colors.bone
};

const TooltipWrapper = (props: Props): JSX.Element => {
  const inverted = props.inverted;
  const tooltipOverlayStyles = {
    borderRadius: 0,
    color: colors.abyss90,
    ...(inverted && invertedStyles),
    ...props.overlayInnerStyle
  };
  return (
    <Tooltip
      {...props}
      arrow={false}
      color={inverted ? colors.eyeBlack : colors.bone}
      overlayClassName={styles.toolTip}
      overlayInnerStyle={tooltipOverlayStyles}
    >
      {props.toggleIcon || <DefaultToggleIcon className={props.className} />}
    </Tooltip>
  );
};

export default TooltipWrapper;
