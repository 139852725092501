import RankDown from "rivals/components/shared/Icons/RankDown";
import RankUp from "rivals/components/shared/Icons/RankUp";
import RivalsLink from "rivals/components/shared/Link";
import { setData } from "rivals/shared/utils/qa";
import styles from "./ProspectRank.module.scss";
import strings from "./strings";
import { Props } from "./types";

function ProspectRank({
  changeValue,
  philter,
  rank,
  rankTitle,
  rankUrl
}: Props): React.JSX.Element {
  return (
    <div className={styles.prospectRank}>
      {rank && rankUrl ? (
        <RivalsLink href={rankUrl}>
          <div className={styles.rankChange}>
            <div
              aria-label={
                changeValue > 0 ? "positive-change" : "negative-change"
              }
              className={styles.arrowIcon}
            >
              {changeValue > 0 && <RankUp />}
              {changeValue < 0 && <RankDown />}
            </div>
            <div
              className={styles.rank}
              {...setData(philter, `${rankTitle}-rank`)}
            >
              {rank}
            </div>
          </div>
          <div className={styles.rankChange}>
            {/* Placeholder rank to match the width to the row above in order to center rank text with number */}
            <div className={styles.arrowIcon}>
              {changeValue !== 0 && <RankUp fill={"transparent"} />}
            </div>
            <div className={styles.rankName}>{rankTitle}</div>
          </div>
        </RivalsLink>
      ) : (
        <div {...setData(philter, `${rankTitle}-rank`)}>
          <div className={styles.rank}>{strings.noRank}</div>
          <div className={styles.rankName}>{rankTitle}</div>
        </div>
      )}
    </div>
  );
}

export default ProspectRank;
