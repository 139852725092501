import classNames from "classnames";
import { CSSProperties } from "react";
import styles from "./GenericCollegeLogo.module.scss";
import type { Props } from "./types";

// Ratio used to determine the fontSize of the letter based on the logoSize
const fontSizeRatio = 0.41;

const GenericCollegeLogo = ({
  collegeName,
  collegeSiteColor,
  logoSize
}: Props): JSX.Element => {
  const collegeNameFirstLetter = collegeName[0];

  const logoStyles = (): CSSProperties => {
    const styles: CSSProperties = {
      fontSize: `${Number(logoSize) * fontSizeRatio}px`,
      height: `${logoSize}px`,
      width: `${logoSize}px`
    };

    if (collegeSiteColor) {
      styles["backgroundColor"] = `#${collegeSiteColor}`;
    }
    return styles;
  };

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.defaultBackground]: !collegeSiteColor
      })}
      style={logoStyles()}
      title={collegeName}
    >
      <div>{collegeNameFirstLetter}</div>
    </div>
  );
};

export default GenericCollegeLogo;
